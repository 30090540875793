<template>
  <div class="header-sub-nav">
    <div class="company-breadcrumb">
      <div class="breadcrumb-link mx-2">
        <v-btn icon to="/admin/companies"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
      </div>
      <div class="company-info">
        <v-avatar>
          <v-img
            :src="
              logo ? logo : require('@/assets/images/company_logos/loading.png')
            "
            contain
            width="60px"
            alt="UBISOFT"
          />
        </v-avatar>
        <v-list-item class="px-2">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ company.toUpperCase() }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-subhead">
              {{ participants }} participants
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
    <div>
      <remove-user-dialog
        v-if="selected.length > 0 && company !== 'nocompany'"
        :removeusers="selected"
        @on-remove-users="removeUsers"
      ></remove-user-dialog>
      <v-dialog v-model="dialogAddUser" persistent max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="blue" v-bind="attrs" v-on="on">
            <v-icon dark>mdi-plus-box</v-icon>
            Add User
          </v-btn>
        </template>
        <v-card class="admin-user-togroup-card">
          <v-card-title class="pa-8">
            <span class="text-title-dialog">Add user to group</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" icon dark @click="dialogAddUser = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-8">
            <v-container>
              <v-select
                v-model="select"

                :items="users"
                item-text="username"
                :label="users.length > 0 ? 'Select people' : 'No people without company'"
                chips
                multiple
                :disabled="users.length === 0"
                :menu-props="{ bottom: true, offsetY: true, closeOnClick: false }"
                prepend-inner-icon="$vuetify.icons.friendsIcon"
                outlined
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      :key="item.username"
                      v-bind="attrs"
                      :input-value="selected"
                      @click:close="parent.selectItem(item)"
                    >
                      <v-avatar
                        class="primary white--text text-h7"
                        left
                        v-text="item.username.slice(0, 1).toUpperCase()"
                      ></v-avatar>
                      <span class="pr-2">
                        {{ item.username }}
                      </span>
                      <v-icon
                        small
                        @click="parent.selectItem(item)"
                      >
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-select>

            </v-container>
            <small></small>
          </v-card-text>
          <v-card-actions justify="center" class="card-actions__wrapper pa-8">
            <v-btn id="action-submit" type="button" @click="onAddUser">
              Add user
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import RemoveUserDialog from "./dialogs/RemoveUserDialog";
import { mapActions } from "vuex";

export default {
  components: {
    RemoveUserDialog,
  },
  props: {
    company: { type: String, default: "ICVR" },
    participants: { type: Number, default: 0 },
    logo: { type: String },
    selected: { type: Array },
  },
  data() {
    return {
      dialogAddUser: false,
      users: [],
      select: [],
      items: [
        {
          text: "Miguel Gilbert",
          role: "Developer",
          group: "ICVR",
        },
        {
          text: "Justin Anderson",
          role: "Manager",
          group: "MESA",
        },
        {
          text: "Jose Stephens",
          role: "Developer",
          group: "UBISOFT",
        },
        {
          text: "Edward Johnson",
          role: "UX Researcher",
          group: "AWS",
        },
        {
          text: "Mark Shelton",
          role: "UI Designer",
          group: "HPA",
        },
        {
          text: "Miguel Gilbert",
          role: "Developer",
          group: "ICVR",
        },
        {
          text: "Eric Dunn",
          role: "QA",
          group: "NBC Universal",
        },
      ],
      isUpdating: false,
    };
  },
  mounted() {
    this.reloadTable();
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    onAddUser() {
      Promise.all(
        this.select.map((user) => {
          return this.updateUsersAttributes(user, this.company);
        })
      )
        .then(() => {
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `New users are added to ${this.company}`,
          });
          this.$emit("reload-request");
        })
        .finally(() => {
          this.dialogAddUser = false;
          this.select = [];
          this.reloadTable();
        });
    },
    reloadTable() {
      this.users = [];
      let self = this;
      awsAuth
        .getUsersOfCompany("nocompany")
        .then((res) => {
          self.users = res.data.map((user) => {
            return {
              username: user.Username,
              name: user.Attributes.find((x) => x.Name == "name")?.Value,
              group: user.Attributes.find((x) => x.Name == "family_name")
                ?.Value,
            };
          });


        })
        .catch(() => {
          self.users = [];
        })
        .finally(() => {});
    },
    removeUsers() {
      Promise.all(
        this.selected.map((user) => {
          return this.updateUsersAttributes(user.username, "nocompany");
        })
      ).then(() => {
        this.snackbar({
          color: "success",
          icon: "mdi-check-circle",
          title: "Success",
        });
        this.$emit("reload-request");
      });
    },
    async updateUsersAttributes(username, companyName) {
      return awsAuth
        .adminUpdateAttributes(username, {
          family_name: companyName,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.isUpdating = false;
        });
    },
    openAddUserDialog() {
      this.dialogAddUser = true;
    },
  },
};
</script>
