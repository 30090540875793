<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-pencil-box</v-icon>
        Edit Company
      </v-btn>
    </template>
    <v-card class="edit-company-detail__card pa-8">
      <v-card-title>
        <span class="text-uppercase text-h4 font-weight-black">{{ form.username }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="(dialog = false), (newImage = form.picture)"
          color="gray"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="text-subhead">
        <v-spacer></v-spacer>
        <v-btn text color="blue" class="text-capitalize pl-0" @click="clickAddUser">
          <v-icon dark>mdi-plus-box</v-icon>
          <span class="btn-span">Add User</span>
        </v-btn>
        <v-btn
          color="red"
          text
          dark
          @click.stop="onClickDeleteGroup"
          class="text-capitalize"
        >
          <icon-delete-box /><span class="pl-2 btn-span">Delete group</span>
        </v-btn>
        <v-dialog v-model="innerConfirmDelete" width="300">
          <v-card class="pa-4">
            <v-card-title class="headline">Delete group?</v-card-title>
            <v-card-text
              >You are trying to delete full company. All participants will lost
              their company. Are you sure?</v-card-text
            >
            <v-card-actions>
              <v-btn color="red" text @click.stop="deleteCompany">Delete</v-btn>
              <v-btn text @click.stop="innerConfirmDelete = false"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-card-text>
        <div class="detail-info_wrapper">
          <div class="detail-info_item">
            <div class="text-lb">
              Company Name
            </div>
            <div class="text-uppercase">
              {{ form.username }}
            </div>
          </div>
          <div class="detail-info_item">
            <div class="text-lb">
              Participants
            </div>
            <div>
              {{ form.participants }}
            </div>
          </div>
        </div>
        <v-file-input
          hide-input
          class="image-target"
          v-model="files"
          prepend-icon=""
          ref="vfileinput"
          accept="image/png, image/jpeg, image/bmp"
          @change="handleUpdate"
        >
          <template slot="append-outer">
            <!-- <template v-slot:append-outer="{}"> -->
            <v-avatar>
              <v-img
                ref="imgFileInput"
                class="company-logo"
                :src="
                  newImage ||
                    require('@/assets/images/company_logos/loading.png')
                "
                width="40px"
                alt="Company logo"
                aspect-ratio="1"
                style="cursor: pointer;"
                @click="$refs.vfileinput.$refs.input.click()"
              />
            </v-avatar>
            <div class="pl-2">
              <p class="text-link text-link-md"
                 @click="$refs.vfileinput.$refs.input.click()"
                 style="cursor: pointer;">
                Upload logotype
              </p>
              <span class="text-sm">PNG or JPEG, 2MB</span>
            </div>
          </template>
        </v-file-input>
        <v-dialog v-model="dialogCropLogo" width="500">
          <v-card>
            <v-card-text>
              <VueCropper
                v-show="selectedFile"
                ref="cropper"
                :src="selectedFile"
                alt="Source Image"
                :autoCropArea="1"
                :aspectRatio="1 / 1"
                :initialAspectRatio="1 / 1"
              ></VueCropper>
            </v-card-text>
            <v-card-actions
              class="image-crop--action"
              style="padding-left: 10px !important"
            >
              <v-btn
                class="primary"
                @click="cropImage(), (dialogCropLogo = false)"
                >Crop</v-btn
              >
              <v-btn color="primary" text @click="dialogCropLogo = false"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions class="card-actions__wrapper">
        <v-btn
          width="100%"
          type="submit"
          color="primary"
          :loading="overlay"
          @click="onUpdateCompany"
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import awsAuth from "@/cognito/aws-auth";
import { mapActions } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import IconDeleteBox from "@/components/icons/IconDeleteBox";
export default {
  components: {
    IconDeleteBox,
    VueCropper,
  },
  props: {
    value: { type: Object, required: true },
    users: { type: Array, default: () => [] },
  },
  data() {
    return {
      dialog: false,
      innerConfirmDelete: false,
      overlay: false,

      files: null,
      dialogCropLogo: false,
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      newImage: "",
      form: {},
    };
  },
  watch: {
    value: {
      handler: function() {
        this.form = { ...this.value };
        this.newImage = this.value.picture;
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    onClickDeleteGroup() {
      this.innerConfirmDelete = true;
    },
    deleteUsers() {
      Promise.all(
        this.users.map((user) => {
          return this.updateUsersAttributes(user.username, 'nocompany');
        })
      )
      .then(() => {
        this.snackbar({
          color: "success",
          icon: "mdi-check-circle",
          title: "Success",
          text: `All users have been removed from ${this.form.username} company`,
        });
        this.form = {};
      })
      .catch((error) => {
        console.log(error.message);
      });
    },
    async updateUsersAttributes(username, companyName) {
      return awsAuth
        .adminUpdateAttributes(username, {
          family_name: companyName,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    async deleteCompany() {
      let self = this;

      const companyName = self.form.username;
      if (self.users.length > 0) {
        self.deleteUsers();
      }

      awsAuth
        .deleteUser(self.form.username, "company")
        .then(() => {
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${companyName} removed`,
          });
          self.dialog = false;
          self.$router.push(`/admin/companies`);
        })
        .catch((error) => {
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    handleUpdate() {
      if (
        !this.files ||
        (this.files.type !== "image/png" &&
          this.files.type !== "image/jpeg" &&
          this.files.type !== "image/bmp")
      ) {
        this.snackbar({
          color: "error",
          icon: "mdi-alert-circle-outline",
          title: "Error",
          text: "Select image file",
        });
        return;
      }
      this.dialogCropLogo = true;

      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        this.selectedFile = e.target.result;
        this.$refs.cropper.replace(this.selectedFile);
      };

      fileReader.readAsDataURL(this.files);
    },
    cropImage() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.newImage = this.cropedImage;
    },
    async saveImage() {
      //make request to upload image to s3
      const payload = {
        fileContent: this.cropedImage,
        username: this.form.username
          ? `${this.form.username}-${Math.random()
              .toString()
              .substr(2, 5)}`
          : `company-logo${Math.random()
              .toString()
              .substr(2, 5)}`,
      };
      await awsAuth
        .uploadImage(payload)
        .then((res) => {
          const v = {
            ...this.value,
            picture: res.data.data.Location,
          };

          this.$emit("input", v);
        })
        .catch((error) => {
          this.notification = `ERROR, ${error.message}!`;
          this.notificationColor = "yellow darken-4";
        })
        .finally(() => {
          this.notify = true;
          this.isUpdating = false;
          this.dialog = false;
        });
    },
    async onUpdateCompany() {
      this.overlay = true;
      let self = this;

      //upload image to AWS s3
      await this.saveImage();

      awsAuth
        .adminUpdateAttributes(
          this.form.username,
          {
            picture: this.newImage ? this.newImage : "",
          },
          "company"
        )
        .then(() => {
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${self.form.username} updated`,
          });
          const form = this.form;
          this.$emit("input", { ...this.value, form });
          this.form = {};
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          this.dialog = false;
          this.overlay = false;
          this.isUpdating = false;
        });
    },
    clickAddUser() {
      this.$emit("click-add-user");
    },
  },
};
</script>
