<template>
  <div class="company-users-page-wrapper">
    <v-row class="company-users-header-nav header-nav">
      <admin-tabs />
      <v-spacer></v-spacer>
      <div class="header-action">
        <edit-company-detail-dialog
          :users="users"
          v-model="companyData"
          @click-add-user="openAddUser"
        ></edit-company-detail-dialog>
      </div>
    </v-row>
    <v-row class="company-users-subheader-nav">
      <admin-sub-tabs
        :company="company"
        :logo="companyData.picture"
        :participants="participants"
        :selected="selectedUsers"
        ref="adminSubTabsCompo"
        @reload-request="reloadPage"
      />
    </v-row>
    <div class="company-users-table">
      <v-data-table
        v-model="selectedUsers"
        :items="users"
        item-key="username"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        :sort-desc="sortDesc"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        show-select
        :headers="headers"
        class="data-table__users"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
        }"
      >
        <template v-slot:item.username="{ item }" class="td-username">
          <v-avatar class="company-user-avatar" color="primary">
            <img
              v-if="item.picture"
              :src="
                item.picture
                  ? item.picture
                  : 'https://cdn.vuetifyjs.com/images/john.jpg'
              "
              alt="John"
            />
            <span v-else class="white--text text-h7">
              {{ item.username.substring(0, 2).toUpperCase() }}
            </span>
          </v-avatar>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h7">
                {{ item.username }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.created="{ item }">
          {{ item.created | formatDate }}
        </template>
        <template slot="no-data">
          <span>No users in this company yet.</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import AdminTabs from "../AdminTabs";
import AdminSubTabs from "./AdminSubTabs";
import EditCompanyDetailDialog from "./dialogs/EditCompanyDetailDialog";
import { Constants } from "@/config/constants";

export default {
  components: {
    AdminTabs,
    AdminSubTabs,
    EditCompanyDetailDialog,
  },
  data() {
    return {
      company: "",
      companyData: {},
      participants: 0,
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      sortBy: [],
      headers: Constants.CompanyUserTableHeaders,
      keys: [
        "Oracle",
        "MESSA",
        "HPA",
        "ICVR",
        "NBC Universal",
        "Unreal Engine",
        "UBISOFT",
      ],
      users: [],
      servers: Constants.Servers,
      selectedUsers: [],
      dialogEdit: false,
      search: "",
      errorMessage: null,
      isLoading: true,
      rules: [],
      allGroups: [],
      allRoles: Constants.AllRoles,
      createForm: {},
      editForm: {},
      dialogCreateInvitation: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.users.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== `Name`);
    },
  },
  mounted() {
    const arr = this.$router.currentRoute.path.split("/");
    this.company = arr.pop();
    this.reloadTable();
    this.getCompanyData();
  },
  methods: {
    getCompanyData() {
      let self = this;
      awsAuth
        .getCompany(this.company)
        .then((res) => {
          self.companyData = {
            username: res.data.data.Username,
            picture: res.data.data.UserAttributes.find(
              (x) => x.Name == "picture"
            )?.Value,
            participants: this.participants,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    reloadTable() {
      let self = this;
      this.users = [];
      awsAuth
        .getUsersOfCompany(this.company)
        .then((res) => {
          self.users = res.data.map((user) => {
            return {
              username: user.Username,
              name: user.Attributes.find((x) => x.Name == "name")?.Value,
              email: user.Attributes.find((x) => x.Name == "email")?.Value,
              picture: user.Attributes.find((x) => x.Name == "picture")?.Value,
              created: user.UserCreateDate.toLocaleString(),
              status: prettyStatus(
                user.Enabled,
                user.UserStatus == "CONFIRMED"
              ),
              group: user.Attributes.find((x) => x.Name == "custom:company")
                ?.Value,
            };
          });
          self.participants = self.users.length;
          self.companyData.participants = self.participants;
        })
        .catch((error) => {
          this.errorMessage = `${error.status}: ${error.message}`;
          this.users = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    reloadPage() {
      this.selectedUsers = [];
      this.reloadTable();
    },
    openAddUser() {
      this.$refs.adminSubTabsCompo.openAddUserDialog();
    },
  },
};

function prettyStatus(isEnabled, isConfirmed) {
  return !isEnabled ? "❌" : isConfirmed ? "✔" : "❔";
}
</script>

<style lang="scss" scoped>
  .company-user-avatar {
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

  }

</style>
